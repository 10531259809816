.navbar {
  background-color: transparent !important;
  margin-bottom: 40px;
}

.center {
  margin: auto;
  width: 85%;
}

.button-margin {
  margin: 10px 3px 0px 3px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  color: black
}